import { DateTime, Interval } from "luxon";
import { setIsAuthenticated } from "modules/user/userSlice";
import { useAppDispatch } from "utils/hooks";
import { getCurrentUserToken } from "modules/user";
import jwt_decode from "jwt-decode";

export const getJWTFromLocalStorage = (): string => {
  const JWT = localStorage.getItem("JWT")
    ? localStorage.getItem("JWT")
    : sessionStorage.getItem("JWT");

  return JWT ? JWT : "";
};
export const clearJWTFromStorage = () => {
  localStorage.removeItem("JWT");
  sessionStorage.removeItem("JWT");
  sessionStorage.removeItem("facilityId");
};

export const jwtHasValidExpiration = (token: string): boolean => {
  if (!token) return false;

  return Interval.fromDateTimes(
    DateTime.now(),
    DateTime.fromSeconds(getConvertToUserToken(token).exp)
  ).isValid;
};

export const getConvertToUserToken = (token: string): UserToken => {
  return jwt_decode(token) as UserToken;
};

export function SetUserIsAuthenticatedValue(value: boolean) {
  const dispatch = useAppDispatch();
  dispatch(setIsAuthenticated({ isAuthenticated: value }));
}

export function getJWTUserProperties(): UserToken | undefined {
  const token = getCurrentUserToken();
  if (!token) return;
  const decoded: UserToken = jwt_decode(token);
  return decoded;
}
