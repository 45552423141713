import { PlayerUser } from "Models";
import { Controller, useFormContext } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { customStyles } from "utils/ui/uiHelpers";
import tailwind from "./StartAdHocRoundContainer.styles";
import { ScoringType } from "types/enums";
import { ErrorMessage } from "@hookform/error-message";

type TeamManagementProps = {
  scoringType?: string;
};

const TeamManagement: React.FC<TeamManagementProps> = ({ scoringType }) => {
  const { watch, setValue, getValues, control } = useFormContext();
  const isScrambleScoring = scoringType === "Scramble";

  let watchPlayers = watch("roundInfo.players");
  let watchTeam1 = watch("teams.0");
  let watchTeam2 = watch("teams.1");
  let watchTeam3 = watch("teams.2");
  let disableTeamTwo =
    watchTeam1?.length > 0 && watchPlayers?.length > 1 ? false : true;
  let disableTeamThree =
    watch("teams.0")?.length > 0 &&
    watch("teams.1")?.length > 0 &&
    watch("roundInfo.players")?.length > 2
      ? false
      : true;

  const convertPlayerToTeamMember = (
    e: { label: string; value: PlayerUser }[],
    team: number
  ) => {
    if (getValues("teams.0")?.length < 1) {
      setValue(
        `teams.0`,
        e.map((player) => player.value.username)
      );
    } else {
      setValue(
        `teams.${team}`,
        e.map((player) => player.value.username)
      );
    }
  };

  const doesScoringTypeIncludeField = (
    scoringType: string,
    fieldName: string
  ): boolean => {
    const scoringTypesAndFields: { [key: string]: Array<string> } = {
      STROKE: ["handicapMode"],
      MATCH: ["teams", "scotchFoursome", "handicapMode"],
      SCRAMBLE: ["mulligans", "teams"],
      STABLEFORD: ["handicapMode", "stableford"],
    };

    return scoringTypesAndFields[scoringType]?.includes(fieldName);
  };

  return (
    <div className="border-t-2 border-b-2 mt-3 mb-3 pt-3 pb-3">
      <div className={tailwind.span}>Manage {scoringType} Teams</div>
      <small className={tailwind.small}>Assign players to a team</small>
      <div className="text-sm font-semibold text-gray-500 dark:text-gray-200 mt-4 mb-2">
        Team 1
      </div>
      <Controller
        name={"teams.0"}
        rules={{
          validate: {
            required: () => {
              if (
                doesScoringTypeIncludeField(
                  ScoringType[watch("roundInfo.scoringType")],
                  "teams"
                ) &&
                watch("teams.0")?.length < 1
              )
                return "At least one player is required";
            },
          },
        }}
        control={control}
        render={({ field }) => (
          <CreatableSelect
            {...field}
            isSearchable={false}
            isClearable={false}
            isMulti
            options={watchPlayers
              ?.filter(
                (player: PlayerUser) => !watchTeam2?.includes(player.username)
              )
              .map((player: PlayerUser) => {
                return {
                  label: player.displayName || player.username,
                  value: player,
                };
              })}
            value={watchPlayers
              ?.filter((player: PlayerUser) =>
                watchTeam1?.includes(player.username)
              )
              .map((player: PlayerUser) => {
                return {
                  label: player.displayName || player.username,
                  value: player,
                };
              })}
            onChange={(e: { label: string; value: PlayerUser }[]) =>
              convertPlayerToTeamMember(e, 0)
            }
            styles={customStyles}
          />
        )}
      />
      <ErrorMessage
        errors={control._formState.errors}
        name="teams.0"
        render={({ message }) => (
          <div className="text-sm text-red-500">{message}</div>
        )}
      />
      <small>* Team 1 must have at least one player</small>
      <div className="text-sm font-semibold text-gray-500 dark:text-gray-200 mt-4 mb-2">
        Team 2
      </div>
      <CreatableSelect
        isSearchable={false}
        isMulti
        options={watchPlayers
          ?.filter(
            (player: PlayerUser) => !watchTeam1?.includes(player.username)
          )
          .map((player: PlayerUser) => {
            return {
              label: player.displayName || player.username,
              value: player,
            };
          })}
        value={watchPlayers
          ?.filter((player: PlayerUser) =>
            watchTeam2?.includes(player.username)
          )
          .map((player: PlayerUser) => {
            return {
              label: player.displayName || player.username,
              value: player,
            };
          })}
        onChange={(e: { label: string; value: PlayerUser }[]) =>
          convertPlayerToTeamMember(e, 1)
        }
        styles={customStyles}
        isDisabled={disableTeamTwo}
      />
      {isScrambleScoring && (
        <>
          <div className="text-sm font-semibold text-gray-500 dark:text-gray-200 mt-4 mb-2">
            Team 3
          </div>
          <CreatableSelect
            isSearchable={false}
            isMulti
            options={watchPlayers
              ?.filter(
                (player: PlayerUser) =>
                  !watchTeam1?.includes(player.username) &&
                  !watchTeam2?.includes(player.username)
              )
              .map((player: PlayerUser) => {
                return {
                  label: player.displayName || player.username,
                  value: player,
                };
              })}
            value={watchPlayers
              ?.filter((player: PlayerUser) =>
                watchTeam3?.includes(player.username)
              )
              .map((player: PlayerUser) => {
                return {
                  label: player.displayName || player.username,
                  value: player,
                };
              })}
            onChange={(e: { label: string; value: PlayerUser }[]) =>
              convertPlayerToTeamMember(e, 2)
            }
            styles={customStyles}
            isDisabled={disableTeamThree}
          />
        </>
      )}
    </div>
  );
};
export default TeamManagement;
