import { useAppDispatch } from "utils/hooks";
import { ModalType } from "utils/modals/modalHelpers";
import { modalAction } from "ui-modules/modals/modalSlice";
import Row from "components/ui/layout/Row";
import Modal from "components/shared/Modal";
import ScorecardSearch from "./ScorecardSearch";

const ScorecardSearchModal: React.FC<ScorecardSearchModalProps> = (props) => {
  const dispatch = useAppDispatch();

  const handleCloseModal = async () => {
    dispatch(
      modalAction({
        isOpen: false,
        modalName: ModalType.ScorecardSearch,
      })
    );
  };

  return (
    <Modal
      modalName={props.modalName}
      closeModalCallback={handleCloseModal}
      hideHeader={false}
      title={props.title || `Scorecard Search`}
      hideFooter={true}
      modalSize="w-full h-full md:w-2/3 h-4/5"
      body={
        <Row cssClasses="px-8 py-4 min-h-full">
          <ScorecardSearch />
        </Row>
      }
    />
  );
};

type ScorecardSearchModalProps = {
  title?: string;
  modalName: string;
};

ScorecardSearchModal.defaultProps = {
  title: "Scorecard Search",
};

export default ScorecardSearchModal;
