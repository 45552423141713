import { useAppDispatch } from "utils/hooks";
import { ModalType } from "utils/modals/modalHelpers";
import { modalAction } from "ui-modules/modals/modalSlice";
import Row from "components/ui/layout/Row";
import Modal from "components/shared/Modal";
import SimulatorReservationContainer from "components/control/round-control/round-configuration-form/SimulatorReservationContainer";

const RexReservationModal: React.FC<RexReservationModalProps> = (props) => {
  const dispatch = useAppDispatch();

  const handleCloseModal = async () => {
    dispatch(
      modalAction({
        isOpen: false,
        modalName: ModalType.RexReservationModal,
      })
    );
  };

  return (
    <Modal
      modalName={props.modalName}
      closeModalCallback={handleCloseModal}
      hideHeader={false}
      title="Confirm Reservation"
      hideFooter={true}
      modalSize="w-full h-full sm:w-2/3 md:1/2 3xl:w-1/3"
      body={
        <Row cssClasses="px-8 pb-4">
          <SimulatorReservationContainer />
        </Row>
      }
    />
  );
};

type RexReservationModalProps = {
  title?: string;
  modalName: string;
};

RexReservationModal.defaultProps = {
  title: "League Roster",
};

export default RexReservationModal;
