export const styles = {
  innerContainer:
    "flex flex-row min-h-screen w-full bg-brandLightGrey dark:bg-zinc-900/80 border border-zinc-200 dark:border-gray-50 rounded-xl m-auto mt-8 px-8",
  checkbox:
    "border-gray-400 bg-gray-100 rounded-sm disabled:bg-gray-300 text-brandGreen focus:ring-0 ring-opacity-0 border-2",
  column:
    "flex flex-none items-center p-2 w-1/3 @2xl/lockerroom:w-1/6 text-left truncate",
  columnHide:
    "hidden @2xl/lockerroom:flex @2xl/lockerroom:flex-none items-center @lg/lockerroom:w-1/6 p-2 w-1/3 text-left truncate",
  row:
    "flex flex-row p-2 border-b bg-white dark:bg-zinc-600 dark:border-zinc-800 dark:text-gray-50",
  buttonIcon:
    "flex dark:text-gray-50 text-gray-400 text-md md:text-base size-3 ml-1 mr-2 drop-shadow-lg",
  activeSort:
    "flex dark:text-gray-800 text-gray-400 fill-black dark:fill-white text-md md:text-base size-3 ml-1 mr-2 drop-shadow-lg",
};
