import { DateTime } from "luxon";
import { IndoorGolfCenterCustomer } from "Models";
import SpinnerAnimation from "components/svg/SpinnerAnimation";
import { styles } from "./lockerRoom.styles";

interface PaginationProps {
  currentCustomers: IndoorGolfCenterCustomer[] | undefined;
  isLoading: boolean;
  sortProperty?: string;
}

const Customers: React.FC<PaginationProps> = ({
  currentCustomers,
  isLoading,
  sortProperty,
}) => {
  return (
    <>
      {isLoading ? (
        <div className={`${styles.row} flex items-center h-3/4 w-full py-8`}>
          <SpinnerAnimation color={"#f2f2e6"} />
        </div>
      ) : currentCustomers && currentCustomers.length > 0 ? (
        currentCustomers?.map((customer, index) => (
          <div key={customer?.userId} className={styles.row}>
            <div className={styles.column}>
              <div className="w-6">{customer?.isFavorite && "⭐️"}</div>
              <span
                className={
                  sortProperty === "lastName" ? "font-bold mr-1" : "mr-1"
                }
              >
                {customer?.lastName?.concat(", ")}
              </span>
              <span className={sortProperty === "firstName" ? "font-bold" : ""}>
                {customer?.firstName}
              </span>
            </div>
            <div className={styles.columnHide}>{customer?.userName}</div>
            <div className={styles.columnHide}>
              <a
                className="border-b border-gray-200 hover:text-gray-200 hover:border-gray-800"
                href={`mailto:${customer?.email}`}
              >
                {customer?.email}
              </a>
            </div>
            <div className={styles.column}>{customer?.daysSeen}</div>
            <div className={styles.columnHide}>{customer?.playedRounds}</div>
            <div className={styles.column}>
              <div className="truncate">
                {DateTime.fromISO(customer?.lastAppearance).toLocaleString(
                  DateTime.DATETIME_MED
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className={styles.row}>No records found</div>
      )}
    </>
  );
};

export default Customers;
