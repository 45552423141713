export const styles = {
  lockerUserInputForm:
    "appearance-none border border-gray-300 dark:border-white rounded-xl w-full py-2 px-3 text-gray-700 dark:text-white dark:bg-zinc-900 focus:outline-none focus:shadow-outline",
  lockerUserInputFormError:
    "appearance-none border border-red-200 rounded-xl w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline dark:text-black",
  lockerUserInputFormButton:
    "poppins font-normal text-brandDarkGray bg-brandGreen text-white hover:bg-brandYellow hover:text-black text-xs rounded-xl px-6 py-2 inline items-center hover:cursor-pointer w-1/2 text-center",
  lockerUserInputFormButtonAlt:
    "ml-2 poppins font-normal text-brandDarkGray bg-darkCream dark:text-black text-xs rounded-xl px-6 py-2 inline items-center hover:cursor-pointer w-1/2 text-center",
  lockerUserInputFormLabel:
    "text-brandDarkGray dark:text-gray-50 text-xs poppins pb-2",
  leagueRosterFormContainer: "flex w-full h-full flex-col",
  leagueGroupFormContainer: "flex w-full flex-col mb-8 h-full",
  leagueGroupsHeader: "border-b border-gray-100 dark:border-gray-50 mb-2 pb-2",
  leagueRosterListItem:
    "grid grid-cols-12 gap-2 py-6 text-sm dark:text-gray-200 justify-items-start items-center content-center border-b border-gray-100 last:border-b-0 poppins",
  leagueRosterActionButton:
    " poppins text-semibold w-full bg-brandYellow text-brandDarkGray shadow-md text-[10px] md:text-xs rounded-lg py-2 px-4 hover:bg-brandGreen hover:text-white cursor-pointer shadow-sm hover:shadow-none ",
  leagueRosterActionButtonAlt:
    "bg-darkCream text-brandDarkGray shadow-md text-xs rounded-lg py-2 px-4 hover:bg-brandGreen hover:text-white cursor-pointer shadow-sm hover:shadow-none ",
  plusIcon:
    "rounded-full bg-gray-50 text-brandGreen text-xl px-2 float-right items-center hover:bg-brandGreen hover:text-white cursor-pointer shadow-sm",
  clickToAddButton:
    "rounded-full bg-gray-50 text-brandGreen text-xs px-2 pt-0.5 float-right items-center hover:bg-brandGreen hover:text-white cursor-pointer shadow-sm",
  minusIcon:
    "rounded-lg bg-gray-50 text-red-500 text-xl px-1 py-0.5 items-center hover:bg-red-500 hover:text-white cursor-pointer shadow",
  createLockerUserContainer: "px-0 text-sm",
  teamName:
    "bg-neutral-100 dark:bg-zinc-800 dark:text-gray-200 rounded-lg py-2 w-full text-sm mr-2",
  editTeamName:
    "border-2 border-gray-100 rounded-lg px-4 py-2 w-full text-sm mr-2",
  leagueEditButton:
    "w-8 h-8 text-gray-400 border border-brandDarkGray hover:bg-gray-100 hover:text-green-600 hover:shadow-inner justify-items-end items-end content-end align-right float-right rounded-tl-lg rounded-bl-lg",
  leagueDeleteButton:
    "w-8 h-8 text-gray-400 border-t border-r border-b border-brandDarkGray hover:bg-gray-100 hover:text-red-600 hover:shadow-inner justify-items-end items-end content-end align-right float-right rounded-tr-lg rounded-br-lg",
  leagueEditDeleteContainer: "dark:bg-zinc-900",
  teamPlayerListContainer:
    "grid grid-cols-12 content-center items-center gap-2 py-3 text-xs poppins w-full text-brandDarkGray dark:text-gray-200 border-b border-gray-100 last:border-none last:pb-4",
  telephoneInput:
    "text-xs text-darkGray border-0 p-0 m-0 cursor-default bg-gray-50 ",
  addGroupContainer: "flex flex-row justify-between items-center mb-4",
  createGroupButton:
    "bg-brandYellow text-brandDarkGray text-[10px] rounded-lg py-1 px-2 poppins",
  noPlayersRosterMessage:
    "text-xs text-gray-400 p-8 dark:bg-zinc-800 dark:text-gray-200 text-center h-full",
  closeIcon:
    "block border border-gray-400 rounded p-1 mb-2 w-6 h-6 text-gray-400 dark:text-gray-200",
  checkbox:
    "border-gray-400 rounded-sm disabled:bg-gray-300 dark:disabled:bg-zinc-900 text-brandGreen focus:ring-0 ring-opacity-0 border-2",
  modalContainer:
    "text-right float-right rounded-tr-lg w-full m-0 background-gray-5- p-8 shadow-lg",
  closeButton:
    "poppins bg-gray-200 text-brandDarkGray border-2 border-brandDarkGray text-xs rounded-lg py-2 px-8 hover:bg-brandYellow",
  modalCloseButton:
    "bg-darkCream dark:bg-zinc-900 text-brandGreen dark:text-gray-200 hover:bg-brandGreen hover:text-darkCream rounded-md px-1 py-1 text-xs poppins shadow mr-4",
  buttonLink: "text-brandDarkGray hover:text-gray-400 text-xs p-2 poppins mr-1",
  modalViewToggleContainer:
    "bg-white dark:bg-zinc-900 poppins p-1 text-xs text-gray-500 dark:text-gray-200 leading-none border border-gray-200 dark:border-gray-500 shadow-sm rounded-xl inline-flex",
  modalViewToggleButtonActive:
    "bg-brandGreen dark:bg-zinc-800 text-white dark:text-gray-200 rounded-lg inline-flex items-center transition-colors duration-300 ease-in focus:outline-none hover:dark-gray px-4 py-2",
  modalViewToggleButtonInactive:
    " inline-flex items-center transition-colors duration-100 ease-out focus:outline-none hover:dark-gray rounded-lg px-4 py-2",
};
