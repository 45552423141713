import { DateTime } from "luxon";
import { Prompt } from "react-router-dom";
import { useEffect, useState } from "react";
import { styles } from "./lockerRoom.styles";
import { FaAngleDown } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";
import { camelCasetoTitleCase } from "utils/enums/enumHelper";
import { FacilityCustomerDto, IndoorGolfCenterCustomer } from "Models";
import { lockerRoomSort } from "utils/arrays/arrayHelpers";
import { customStyles, useSidebarCollapsed } from "utils/ui/uiHelpers";
import { leagueQueryDefaultOptions } from "modules/leagues/leagueHelpers";
import { BreakPoints, useMediaQuery } from "utils/animations/animationHelper";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import axios from "axios";
import Tippy from "@tippyjs/react";
import Row from "components/ui/layout/Row";
import Pagination from "./Pagination";
import Customers from "./Customers";
import Select from "react-select";
import toast from "react-hot-toast";
import ReservationsIcon from "components/svg/ReservationsIcon";
import SortIcon from "components/svg/SortIcon";
import {
  LocalStorageItems,
  setLocalStorageValue,
  getLocalStorageValue,
} from "utils/hooks/localStorage";
import { GoSortAsc, GoSortDesc } from "react-icons/go";

const LockerRoom = () => {
  const { lockerRoom } = useFlags();

  const dateRangeOptions = [
    { label: "Last 30 Days", months: 1 },
    { label: "3 Months", months: 3 },
    { label: "6 Months", months: 6 },
    { label: "9 Months", months: 9 },
    { label: "12 Months", months: 12 },
  ];

  const [allCustomers, setAllCustomers] = useState<FacilityCustomerDto>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [customersPerPage, setCustomersPerPage] = useState<{
    label: string;
    value: number;
  }>({ label: "25", value: 25 });
  const [dateRange, setDateRange] = useState<string>(
    getLocalStorageValue(LocalStorageItems.LOCKERROOMDATERANGE) ||
      "Last 30 Days"
  );
  const [startDate, setStartDate] = useState<string>(
    dateRange
      ? DateTime.now()
          .minus({
            months:
              dateRangeOptions.find((range) => range.label === dateRange)
                ?.months || 1,
          })
          .toLocaleString(DateTime.DATE_FULL)
      : ""
  );

  const [searchItem, setSearchItem] = useState("");

  const [sortAscending, setSortAscending] = useState<boolean>(true);
  const [sortProperty, setSortProperty] = useState<string>("lastName");

  const isSidebarCollapsed = useSidebarCollapsed();
  const isDesktop = useMediaQuery(BreakPoints.LG);
  const currentFacilityId = useGetCurrentLocalFacilityId();

  const indexOfLastPost = currentPage * customersPerPage.value;
  const indexOfFirstPost = indexOfLastPost - customersPerPage.value;

  const filterCustomers = searchItem?.length
    ? lockerRoomSort(
        allCustomers?.customers?.filter(
          (customer) =>
            customer?.firstName
              ?.toLowerCase()
              .includes(searchItem.toLowerCase()) ||
            customer?.lastName?.toLowerCase().includes(searchItem.toLowerCase())
        ) || [],
        sortProperty as keyof IndoorGolfCenterCustomer,
        sortAscending
      )
    : lockerRoomSort(
        allCustomers?.customers || [],
        sortProperty as keyof IndoorGolfCenterCustomer,
        sortAscending
      );

  const currentCustomers = filterCustomers?.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleInputChange = (e: { target: { value: any } }) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);
  };

  const exportLockerData = () => {
    if (!allCustomers?.customers?.length) return;

    let excludedKeys = [
      "userId",
      "userGuid",
      "isDeleted",
      "playedSessions",
      "hasOnlineAccount",
    ];
    const header = Object.keys(allCustomers.customers[0])
      ?.filter((key) => !excludedKeys.includes(key))
      .map((key) => key);

    const csv = [
      header
        .map((column) =>
          camelCasetoTitleCase(column).replace("Appearance", "Appearance (UTC)")
        )
        .join(","),
      ...allCustomers.customers.map((row) =>
        header
          .map((field) => {
            const value = (row as any)[field];
            if (typeof value === "boolean") {
              return value ? "Yes" : "No";
            }
            return value ?? "";
          })
          .join(",")
      ),
    ].join("\r\n");

    const link = document.createElement("a");
    link.href =
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv);
    link.download = `LockerRoomExport-${DateTime.now().toLocaleString()}.csv`;
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    toast.success(
      "Data has been downloaded successfully. Check your downloads folder to view the file.",
      { duration: 5000 }
    );
  };

  const CustomersHeader = () => {
    function handleRosterSortOnClick(sortProperty: string) {
      setSortProperty(sortProperty);
      setSortAscending(!sortAscending);
    }
    function highlightSearchProperty(property: string) {
      return property === sortProperty
        ? "font-bold text-zinc-800 dark:text-white"
        : "";
    }
    function showSortIcon(property: string) {
      return property === sortProperty ? (
        sortAscending ? (
          <GoSortAsc className={styles.activeSort} />
        ) : (
          <GoSortDesc className={styles.activeSort} />
        )
      ) : (
        <SortIcon class={styles.buttonIcon} />
      );
    }

    return (
      <div className="flex flex-row p-2 bg-zinc-100 dark:bg-zinc-800 text-gray-500 dark:text-gray-50 rounded-tl-xl rounded-tr-xl">
        <div className={`${styles.column} pl-8`}>
          <span className={highlightSearchProperty("lastName")}>Last</span>
          <button onClick={() => handleRosterSortOnClick("lastName")}>
            {showSortIcon("lastName")}
          </button>
          <span className={highlightSearchProperty("firstName")}>First</span>
          <button onClick={() => handleRosterSortOnClick("firstName")}>
            {showSortIcon("firstName")}
          </button>
        </div>
        <div
          className={`${styles.columnHide} ${highlightSearchProperty(
            "userName"
          )}`}
        >
          Username
          <button onClick={() => handleRosterSortOnClick("userName")}>
            {showSortIcon("userName")}
          </button>
        </div>
        <div
          className={`${styles.columnHide} ${highlightSearchProperty("email")}`}
        >
          Email
          <button onClick={() => handleRosterSortOnClick("email")}>
            {showSortIcon("email")}
          </button>
        </div>
        <div
          className={`${styles.column} ${highlightSearchProperty("daysSeen")}`}
        >
          Visits{" "}
          <button onClick={() => handleRosterSortOnClick("daysSeen")}>
            {showSortIcon("daysSeen")}
          </button>
        </div>
        <div
          className={`${styles.columnHide} ${highlightSearchProperty(
            "playedRounds"
          )}`}
        >
          Rounds{" "}
          <button onClick={() => handleRosterSortOnClick("playedRounds")}>
            {showSortIcon("playedRounds")}
          </button>
        </div>
        <div
          className={`${styles.column} ${highlightSearchProperty(
            "lastAppearance"
          )}`}
        >
          Last Visit
          <button onClick={() => handleRosterSortOnClick("lastAppearance")}>
            {showSortIcon("lastAppearance")}
          </button>
        </div>
      </div>
    );
  };

  const DateFilter = () => {
    return (
      <Tippy
        className="poppins text-black text-xs bg-gray-100 dark:bg-zinc-800 dark:text-gray-50 left-0 top-2 rounded float-right inline"
        placement="bottom"
        allowHTML={true}
        appendTo={document.body}
        interactive={true}
        interactiveBorder={5}
        zIndex={9999}
        content={
          <ul className="z-9999 p-2 border-2 rounded-xl w-40">
            {dateRangeOptions
              .filter((range) => range.label !== dateRange)
              .map(({ label, months }) => (
                <li
                  key={`filter-view${months}mo`}
                  className="border-b border-gray-200 px-1 py-2 last:border-none text-left"
                  onClick={() => {
                    setStartDate(
                      DateTime.now()
                        .minus({ months })
                        .toLocaleString(DateTime.DATE_FULL)
                    );
                    setDateRange(label);
                    setLocalStorageValue(
                      LocalStorageItems.LOCKERROOMDATERANGE,
                      label
                    );
                  }}
                >
                  <button className="cursor-pointer">{label}</button>
                </li>
              ))}
          </ul>
        }
      >
        <div className="rounded-lg border flex items-center p-3 text-zinc-800 dark:text-gray-50 border-gray-100 dark:border-zinc-800 bg-white hover:bg-gray-100 hover:border-gray-400 dark:bg-zinc-600 cursor-pointer text-left text-xs w-40">
          <ReservationsIcon class="size-4 fill-gray-800 text-zinc-800 mr-1" />
          {dateRange}
          <FaAngleDown className="size-3 fill-gray-300 text-gray-300 ml-4" />
        </div>
      </Tippy>
    );
  };

  useEffect(() => {
    async function fetchCustomers() {
      setIsLoading(true);
      const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${
        process.env.REACT_APP_BASE_URL
      }/api/facility/${currentFacilityId}/players${
        startDate &&
        `?startDate=${startDate}&endDate=${DateTime.local()
          .minus({ days: 1 })
          .toLocaleString(DateTime.DATE_FULL)}`
      }`;
      const { data } = await axios.get<FacilityCustomerDto>(
        url,
        leagueQueryDefaultOptions()
      );

      setAllCustomers(data);
      setIsLoading(false);
    }

    if (lockerRoom) {
      fetchCustomers();
    }
  }, [currentFacilityId, lockerRoom, startDate]);

  return (
    <div
      className={`@container/lockerRoom lockerRoom w-full ${
        isDesktop
          ? isSidebarCollapsed
            ? "pl-24"
            : "pl-64"
          : "px-4 xl:pl-0 pt-8"
      }`}
    >
      <Prompt
        when={isLoading}
        message="Are you sure you want to navigate away from Locker Room? Data has not fully loaded, so will not save."
      />
      <Row cssClasses={`flex flex-col md:flex-row min-h-screen w-full mt-4`}>
        <AnimatePresence>
          <motion.div
            className={`w-full my-6 ml-0 sm:mx-8 @container/lockerroom`}
            key="singleFacilityControlDashboard"
          >
            <div className="md:flex md:items-center md:justify-between">
              <div className="flex min-w-0">
                <div className="flex align-left flex-row items-center">
                  <input
                    type="text/css"
                    className="h-10 max-w-32 mr-2 px-4 text-xs border rounded-xl border-gray-100 bg-white text-zinc-800"
                    placeholder="Search by name"
                    value={searchItem}
                    onChange={handleInputChange}
                  />
                  <button
                    onClick={() => exportLockerData()}
                    className="bg-brandGreen text-white rounded-lg poppins text-xs px-4 py-2"
                  >
                    Export {allCustomers?.customers?.length} customers
                  </button>
                  <small className="text-xs text-gray-400 dark:text-gray-50 ml-2"></small>
                </div>
              </div>
              <div className="mt-4 flex md:mt-0 md:ml-4 items-center justify-center">
                <DateFilter />
              </div>
            </div>
            <Row cssClasses="pt-4">
              <div className="flex flex-col rounded-xl border border-zinc-200 dark:border-zinc-900 poppins text-xs">
                <CustomersHeader />
                <Customers
                  isLoading={isLoading}
                  currentCustomers={
                    searchItem ? filterCustomers : currentCustomers
                  }
                  sortProperty={sortProperty}
                />
                <div className="flex flex-row p-2 bg-white dark:bg-zinc-800 text-gray-500 dark:text-gray-50 rounded-bl-xl rounded-br-xl">
                  <div className="flex w-full p-2 text-right items-center justify-end">
                    <Pagination
                      postsPerPage={customersPerPage.value}
                      totalPosts={allCustomers?.customers?.length || 0}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                    <Select
                      options={[
                        { label: "25", value: 25 },
                        { label: "50", value: 50 },
                        { label: "100", value: 100 },
                        { label: "All", value: 1000000 },
                      ]}
                      defaultValue={customersPerPage}
                      onChange={(val: { label: string; value: number }) => {
                        setCustomersPerPage(val);
                        setCurrentPage(1);
                      }}
                      styles={customStyles}
                      className="w-32 text-xs ml-2 text-left"
                    />
                  </div>
                </div>
              </div>
            </Row>
          </motion.div>
        </AnimatePresence>
      </Row>
    </div>
  );
};
export default LockerRoom;
