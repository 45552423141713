export enum LocalStorageItems {
  DARKMODE = "agClubhouseDarkModeActive",
  DASHBOARDQUEUE = "agClubhouseDashboardQueue",
  DASHBOARDGRIDORLISTVIEW = "agClubhouseDashboardGridOrListView",
  SHOWEVENTSLIST = "agClubhouseEventsList",
  BROWSERID = "agClubhouseBrowserId",
  LOCKERROOMDATERANGE = "agClubhouseLockerRoomDateRange",
  LEAGUEFILTERS = "agClubhouseLeagueFilters",
}

export function localStorageValueToBoolean(value: string | null): boolean {
  return value && value === "true" ? true : false;
}

export function getLocalStorageValue(key: string): string {
  const localValue = localStorage.getItem(key);
  return localValue ? localValue : "";
}

export async function setLocalStorageValue(key: string, value: string) {
  localStorage.setItem(key, value);
}

export function getSessionStorageValue(key: string): string {
  const localValue = sessionStorage.getItem(key);
  return localValue ? localValue : "";
}

export function setSessionStorageValue(key: string, value: string) {
  sessionStorage.setItem(key, value);
}
