import {
  FacilityNormalized,
  ScorecardImageType,
  ScorecardTextType,
} from "EntityTypes";
import {
  CourseListMessage,
  RoundEndedMessage,
  RoundUpdateMessage,
  ScorecardMessage,
  SendRoundRangeToSimMessage,
  ServiceRequestMessage,
  SimulatorStatusMessage,
  TimerUpdateMessage,
} from "Messages";
import { Course, ServiceRequest, SimulatorState } from "Models";
import { getCurrentUserToken } from "modules/user";
import { updateEntities } from "redux-query";
import {
  withCallbacks,
  signalMiddleware,
  HubConnectionBuilder,
  LogLevel,
} from "updated-redux-signalr";
import store from "store/rootReducer";
import {
  CourseEntitiesState,
  EntitiesById,
  RoundsEntitiesState,
  ServiceRequestEntityState,
  SimulatorsEntitiesState,
} from "StoreTypes";
import { ServiceRequestType, SimStatus } from "types/enums";
import { serviceRequestEnumDisplayName } from "utils/enums/enumHelper";
import {
  GenericNotificationType,
  showCustomNotification,
} from "utils/notifications/notificationHelpers";
import notificationMp3 from "notification.mp3";
import { timerStringToTimerArray } from "utils/timer/timerHelpers";
import { setSelectedSimulatorReservation } from "ui-modules/simulator-reservation/simulatorReservationSlice";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { modalAction } from "ui-modules/modals/modalSlice";
import { ModalType } from "utils/modals/modalHelpers";

const token = () => getCurrentUserToken();
const client = `${process.env.REACT_APP_WSS_PROTOCOL}${process.env.REACT_APP_WEBSOCKETS_BASE}/sockets/control`;
const notification = new Audio(notificationMp3);
const playSound = () => {
  notification.play();
};

const messageSimulatorBelongToUser = (messageSimulatorId: string): boolean => {
  const userSimulatorIds: string[] = store.getState().entities?.simulators
    ? Object.keys(store.getState().entities.simulators)
    : [];

  return userSimulatorIds?.includes(messageSimulatorId);
};

const getFacilityById = (facilityId: string): FacilityNormalized => {
  const selectedFacility: FacilityNormalized = store.getState().entities
    ?.facilities?.[facilityId];

  return selectedFacility;
};

const isMessageObject = (str: any): boolean => typeof str === "object";

export const connection = new HubConnectionBuilder()
  .configureLogging(LogLevel.Debug)
  .withUrl(client, {
    accessTokenFactory: () => token(),
  })
  .withAutomaticReconnect()
  .build();

const callbacks = withCallbacks()
  .add(
    "ReceiveCourseListMessage",
    (message: CourseListMessage | string) => (dispatch) => {
      const parsedMessage: CourseListMessage = isMessageObject(message)
        ? message
        : JSON.parse(message as string);

      let obj: { [id: string]: Course } = {};
      parsedMessage.courses.forEach(
        (course: Course) => (obj[course.shortName] = course)
      );
      delete obj["range"];
      dispatch(
        updateEntities({
          courses: (prev: CourseEntitiesState) => ({
            ...prev,
            ...{ [parsedMessage.simulatorId]: obj },
          }),
        })
      );
    }
  )
  .add(
    "ReceiveSendRoundRangeToSimMessage",
    (message: SendRoundRangeToSimMessage | string) => (dispatch) => {
      const currentFacilityId = useGetCurrentLocalFacilityId();
      const parsed: SendRoundRangeToSimMessage = isMessageObject(message)
        ? message
        : JSON.parse(message as string);
      playSound();

      if (currentFacilityId === parsed.sendRoundRangeToSimInfo?.facilityId) {
        dispatch(
          setSelectedSimulatorReservation({
            selectedSimulatorReservation: parsed.sendRoundRangeToSimInfo,
          })
        );
        dispatch(
          modalAction({
            isOpen: true,
            modalName: ModalType.RexReservationModal,
          })
        );

        showCustomNotification(
          `A new reservation has been made for ${parsed.sendRoundRangeToSimInfo?.numberInParty} players. Confirm reservation and send to sim to begin.`,
          GenericNotificationType.SUCCESS
        );
      } else {
        const selectedFacility = getFacilityById(
          parsed.sendRoundRangeToSimInfo?.facilityId
        );
        showCustomNotification(
          `A new reservation has been made for ${selectedFacility.name}`,
          GenericNotificationType.SUCCESS
        );
      }
    }
  )
  .add(
    "ReceiveSimulatorServiceRequestMessage",
    (message: ServiceRequestMessage | string) => (dispatch) => {
      const parsed: ServiceRequestMessage = isMessageObject(message)
        ? message
        : JSON.parse(message as string);
      const request = parsed.request;
      console.log(request);
      if (messageSimulatorBelongToUser(parsed.simulatorId)) {
        playSound();
        showCustomNotification(
          `${serviceRequestEnumDisplayName(
            ServiceRequestType[request.type]
          )} Request Recieved: See full notification for more info`,
          GenericNotificationType.SUCCESS
        );

        const obj: {
          [serviceRequestId: string]: ServiceRequest;
        } = {};
        obj[request.id] = request;
        dispatch(
          updateEntities({
            serviceRequestsByFacilityId: (prev: ServiceRequestEntityState) => ({
              ...prev,
              ...{
                [request.facilityId]: { ...prev[request.facilityId], ...obj },
              },
            }),
          })
        );
      }
    }
  )
  .add(
    "ReceiveSimulatorRoundBegan",
    (message: RoundUpdateMessage | string) => (dispatch) => {
      const parsedMessage: RoundUpdateMessage = isMessageObject(message)
        ? message
        : JSON.parse(message as string);

      if (messageSimulatorBelongToUser(parsedMessage.simulatorId)) {
        const round = parsedMessage.round;
        const roundObject = { [parsedMessage.roundId]: round };
        const simStateUpdate = {
          roundId: parsedMessage.roundId,
          estimatedTimeRemaining: parsedMessage.estimatedTimeRemaining,
        };
        dispatch(
          updateEntities({
            simulatorStates: (prev: SimulatorsEntitiesState) => ({
              ...prev,
              ...{
                [parsedMessage.simulatorId]: {
                  ...prev[parsedMessage.simulatorId],
                  ...simStateUpdate,
                },
              },
            }),
            rounds: (prev: RoundsEntitiesState) => ({
              ...prev,
              ...roundObject,
            }),
          })
        );
      }
    }
  )
  .add(
    "ReceiveSimulatorRoundUpdate",
    (message: RoundUpdateMessage | string) => (dispatch) => {
      const parsedMessage: RoundUpdateMessage = isMessageObject(message)
        ? message
        : JSON.parse(message as string);

      if (messageSimulatorBelongToUser(parsedMessage.simulatorId)) {
        const round = parsedMessage.round;
        const roundObject = { [parsedMessage.roundId]: round };
        const simStateUpdate = {
          roundId: parsedMessage.roundId,
          estimatedTimeRemaining: parsedMessage.estimatedTimeRemaining,
        };
        dispatch(
          updateEntities({
            simulatorStates: (prev: SimulatorsEntitiesState) => ({
              ...prev,
              ...{
                [parsedMessage.simulatorId]: {
                  ...prev[parsedMessage.simulatorId],
                  ...simStateUpdate,
                },
              },
            }),
            rounds: (prev: RoundsEntitiesState) => ({
              ...prev,
              ...roundObject,
            }),
          })
        );
      }
    }
  )
  .add(
    "ReceiveSimulatorRoundEnded",
    (message: RoundEndedMessage | string) => (dispatch) => {
      const parsedMessage: RoundEndedMessage = isMessageObject(message)
        ? message
        : JSON.parse(message as string);

      if (messageSimulatorBelongToUser(parsedMessage.simulatorId)) {
        const round = parsedMessage.round;
        const roundObject = { [parsedMessage.roundId]: round };
        const simStateUpdate = {
          roundId: parsedMessage.roundId,
          estimatedTimeRemaining: "",
          status: SimStatus.STATUS_SIM_EXIT,
        };
        dispatch(
          updateEntities({
            simulatorStates: (prev: SimulatorsEntitiesState) => ({
              ...prev,
              ...{
                [parsedMessage.simulatorId]: {
                  ...prev[parsedMessage.simulatorId],
                  ...simStateUpdate,
                },
              },
            }),
            rounds: (prev: RoundsEntitiesState) => ({
              ...prev,
              ...roundObject,
            }),
          })
        );
      }
    }
  )
  .add(
    "ReceiveSimulatorTimerUpdate",
    (message: TimerUpdateMessage | string) => (dispatch) => {
      const parsedMessage: TimerUpdateMessage = isMessageObject(message)
        ? message
        : JSON.parse(message as string);
      if (messageSimulatorBelongToUser(parsedMessage.simulatorId)) {
        const simStateUpdate = {
          timerValue: timerStringToTimerArray(parsedMessage.value),
          timerStatus: parsedMessage.status,
        };
        dispatch(
          updateEntities({
            simulatorStates: (prev: EntitiesById<SimulatorState>) => ({
              ...prev,
              ...{
                [parsedMessage.simulatorId]: {
                  ...prev[parsedMessage.simulatorId],
                  ...simStateUpdate,
                },
              },
            }),
          })
        );
      }
    }
  )
  .add(
    "ReceiveSimulatorStatus",
    (message: SimulatorStatusMessage | string) => (dispatch) => {
      const parsedMessage: SimulatorStatusMessage = isMessageObject(message)
        ? message
        : JSON.parse(message as string);
      if (messageSimulatorBelongToUser(parsedMessage.simulatorId)) {
        const simStateUpdate = {
          status: parsedMessage.status,
        };
        dispatch(
          updateEntities({
            simulatorStates: (prev: EntitiesById<SimulatorState>) => ({
              ...prev,
              ...{
                [parsedMessage.simulatorId]: {
                  ...prev[parsedMessage.simulatorId],
                  ...simStateUpdate,
                },
              },
            }),
          })
        );
      }
    }
  )
  .add(
    "ReceiveTextScorecardMessage",
    (message: ScorecardMessage | string) => (dispatch) => {
      const parsedMessage: ScorecardMessage = isMessageObject(message)
        ? message
        : JSON.parse(message as string);
      if (messageSimulatorBelongToUser(parsedMessage.simulatorId)) {
        const textScorecard = btoa(parsedMessage.textScorecard);
        dispatch(
          updateEntities({
            scorecardTextStates: (prev: ScorecardTextType) => ({
              ...prev,
              ...{
                [parsedMessage.simulatorId]: textScorecard,
              },
            }),
          })
        );
      }
    }
  )
  .add(
    "ReceiveImageScorecardMessage",
    (message: ScorecardMessage | string) => (dispatch) => {
      const parsedMessage: ScorecardMessage = isMessageObject(message)
        ? message
        : JSON.parse(message as string);
      if (messageSimulatorBelongToUser(parsedMessage.simulatorId)) {
        const imageScorecard = parsedMessage.base64ImageScorecard;
        dispatch(
          updateEntities({
            scorecardImageStates: (prev: ScorecardImageType) => ({
              ...prev,
              ...{
                [parsedMessage.simulatorId]: imageScorecard,
              },
            }),
          })
        );
      }
    }
  )
  .add(
    "ReceiveSimulatorMessageProcessed",
    (message: ScorecardMessage | string) => (dispatch) => {
      const parsedMessage: ScorecardMessage = isMessageObject(message)
        ? message
        : JSON.parse(message as string);
      console.log(parsedMessage);
    }
  );

export const signalRMiddleware = signalMiddleware({
  callbacks,
  connection,
  shouldConnectionStartImmediately: false,
});
