import axios from "axios";
import { getCurrentUserToken } from "modules/user";

export enum ClaimIds {
  CLUBHOUSE_ADMIN = "clubhouse-admin",
}

export async function fetchUserClaim(claimId?: string) {
  const token = getCurrentUserToken();
  if (!token) return [];

  const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/roles/roles_for_claims`;
  const { data } = await axios.get<{ [key: string]: string }>(url, {
    headers: { authorization: `Bearer ${token}` },
  });

  if (claimId) {
    if (data.hasOwnProperty(claimId)) {
      let value: string = data[claimId];

      return value;
    }
    return "";
  } else {
    return "";
  }
}

export async function validateUserClaims(claimId: string, facilityId: string) {
  const token = getCurrentUserToken();
  if (!token || !claimId) return [];

  const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${
    process.env.REACT_APP_BASE_URL
  }/api/users/check_claim?claimId=${encodeURIComponent(
    claimId
  )}&facilityIds=${facilityId}`;
  const { data } = await axios.get<boolean>(url, {
    headers: { authorization: `Bearer ${token}` },
  });
  return data;
}

export async function hasClubhouseAdminClaim(facilityId: string) {
  const userClaim = await fetchUserClaim(ClaimIds.CLUBHOUSE_ADMIN);
  if (!userClaim) return false;

  const validateClaims = await validateUserClaims(
    userClaim as string,
    facilityId
  ).then((res) => res as boolean);

  return validateClaims;
}
