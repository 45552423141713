export namespace ArrayHelper {
  export const fillWithRange = (start: number, end: number): number[] => {
    return Array(end - start + 1)
      .fill(null)
      .map((_, idx) => start + idx);
  };

  export const convertBooleanToNumberArray = (array: boolean[]): number[] => {
    return array.flatMap((bool, index) => (bool ? index + 1 : []));
  };

  export const arrayGroupBy = <T>(keys: (keyof T)[]) => (
    array: T[]
  ): Record<string, T[]> =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = keys.map((key) => obj[key]).join("-");
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {} as Record<string, T[]>);
}
export const sortStringArrayByProperty = <T>(
  array: T[],
  property: keyof T,
  ascending: boolean = true,
  nestedProperty?: keyof T
): T[] => {
  return array.sort((a, b) => {
    const valueA = String(a[property]).toLowerCase();
    const valueB = String(b[property]).toLowerCase();

    if (valueA < valueB) {
      return ascending ? -1 : 1;
    }
    if (valueA > valueB) {
      return ascending ? 1 : -1;
    }
    return 0;
  });
};

export const sortNumberArrayByProperty = <T>(
  array: T[],
  property: keyof T,
  ascending: boolean = true
): T[] => {
  if (ascending) {
    return array.sort(function (a, b) {
      return Number(a) - Number(b);
    });
  } else {
    return array.sort(function (a, b) {
      return Number(b) - Number(a);
    });
  }
};

export const nestedSort = (
  prop1: any,
  prop2: any = null,
  direction = "asc"
) => (e1: any, e2: any) => {
  const a = prop2 ? e1[prop1][prop2] : e1[prop1],
    b = prop2 ? e2[prop1][prop2] : e2[prop1],
    sortOrder = direction === "asc" ? 1 : -1;
  return a < b ? -sortOrder : a > b ? sortOrder : 0;
};

export const sortArrayCaseInsensitive = <T>(
  array: T[],
  property: keyof T,
  ascending: boolean = true
): T[] => {
  return array.sort((a, b) => {
    if (typeof a[property] === "string" && typeof b[property] === "string") {
      const valueA = String(a[property]).toLowerCase().trim();
      const valueB = String(b[property]).toLowerCase().trim();

      if (valueA < valueB) {
        return ascending ? -1 : 1;
      }
      if (valueA === valueB) {
        return ascending ? -1 : 1;
      }
      if (valueA > valueB) {
        return ascending ? 1 : -1;
      }
      return 0;
    } else {
      const valueA = Number(a[property]);
      const valueB = Number(b[property]);

      return ascending ? valueA - valueB : valueB - valueA;
    }
  });
};

export const lockerRoomSort = <T>(
  array: T[],
  property: keyof T,
  ascending: boolean = true
): T[] => {
  const definedItems = array.filter((item) => item[property] !== undefined);
  const undefinedItems = array.filter((item) => item[property] === undefined);

  const sortedDefinedItems = definedItems.sort((a, b) => {
    if (typeof a[property] === "string" && typeof b[property] === "string") {
      const valueA = String(a[property]).toLowerCase().trim();
      const valueB = String(b[property]).toLowerCase().trim();

      if (valueA < valueB) {
        return ascending ? -1 : 1;
      }
      if (valueA === valueB) {
        return ascending ? -1 : 1;
      }
      if (valueA > valueB) {
        return ascending ? 1 : -1;
      }
      return 0;
    } else {
      const valueA = Number(a[property]);
      const valueB = Number(b[property]);

      return ascending ? valueA - valueB : valueB - valueA;
    }
  });

  return [...sortedDefinedItems, ...undefinedItems];
};
