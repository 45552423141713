import { AppQueryConfig } from "QueryTypes";
import {
  leagueQueryDefaultOptions,
  leagueQueryLockerAPIDefaultOptions,
} from "./leagueHelpers";
import { LeagueRosterEntryDto, LockerUser } from "Models";
import axios from "axios";

const urls = {
  addPlayerToRoster(
    facilityId: string,
    leagueId: string,
    userId: string
  ): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/player/${userId}`;
  },
  addPlayersToRoster(facilityId: string, leagueId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/players`;
  },
  getAllPlayersInLeague(facilityId: string, leagueId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/players`;
  },
  createLockerUser(): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/partner/user`;
  },
  deletePlayerFromRoster(
    facilityId: string,
    leagueId: string,
    playerId: string
  ): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/player/${playerId}/`;
  },
};

export const getRosterByLeagueId = (
  facilityId: string,
  leagueId: string
): AppQueryConfig => ({
  url: urls.getAllPlayersInLeague(facilityId, leagueId),
  options: {
    ...leagueQueryDefaultOptions(),
    method: "GET",
  },
  force: true,
  transform: (responseJson: LeagueRosterEntryDto[]) => {
    let obj: { [id: string]: LeagueRosterEntryDto } = {};

    responseJson.map((player) => {
      return (obj[player?.userGuid] = player);
    });

    return {
      leagueRoster: {
        byLeagueId: {
          [leagueId]: obj,
        },
      },
    };
  },
  update: {
    leagueRoster: (oldValue, newValue) => ({
      ...oldValue,
      byLeagueId: {
        ...oldValue?.byLeagueId,
        ...{
          [leagueId]: {
            ...oldValue?.byLeagueId?.[leagueId],
            ...newValue.byLeagueId[leagueId],
          },
        },
      },
    }),
  },
});

export const getAllPlayersInLeague = async (
  facilityId: string,
  leagueId: string
): Promise<any> => {
  return await axios
    .get<LeagueRosterEntryDto[]>(
      urls.getAllPlayersInLeague(facilityId, leagueId),
      {
        ...leagueQueryDefaultOptions(),
      }
    )
    .then((res) => {
      return {
        status: res.status,
        data: res.data,
      };
    })
    .catch((error) => {
      return { data: error.response, status: error.response.status };
    });
};

export const makeAddPlayerToRoster = async (
  facilityId: string,
  leagueId: string,
  userId: string
): Promise<any> => {
  return await axios
    .put<LeagueRosterEntryDto>(
      urls.addPlayerToRoster(facilityId, leagueId, userId),
      {},
      {
        ...leagueQueryDefaultOptions(),
      }
    )
    .then((res) => {
      return {
        status: res.status,
        data: res.data,
      };
    })
    .catch((error) => {
      return { data: error.response, status: error.response.status };
    });
};

export const makeDeletePlayerFromRoster = async (
  facilityId: string,
  leagueId: string,
  playerId: string
): Promise<any> => {
  return await axios
    .delete<LeagueRosterEntryDto>(
      urls.deletePlayerFromRoster(facilityId, leagueId, playerId),
      {
        ...leagueQueryDefaultOptions(),
      }
    )
    .then((res) => {
      return {
        status: res.status,
        data: res.data,
      };
    })
    .catch((error) => {
      return { data: error.response, status: error.response.status };
    });
};

export const makeAddPlayersToRosterMutation = (
  facilityId: string,
  leagueId: string,
  playerId: string
): AppQueryConfig => ({
  url: urls.addPlayerToRoster(facilityId, leagueId, playerId),
  queryKey: `makeAddPlayersToRosterMutation:${facilityId}-${leagueId}-${playerId}`,
  options: {
    ...leagueQueryDefaultOptions(),
    method: "PUT",
  },
  transform: (responseJson: LeagueRosterEntryDto[]) => {
    let obj: { [id: string]: LeagueRosterEntryDto } = {};

    responseJson.map((player) => {
      return (obj[player?.userGuid] = player);
    });

    return {
      leagueRoster: {
        byLeagueId: {
          [leagueId]: obj,
        },
      },
    };
  },
  update: {
    leagueRoster: (oldValue, newValue) => ({
      ...oldValue,
      byLeagueId: {
        ...oldValue?.byLeagueId,
        ...{
          [leagueId]: {
            ...oldValue?.byLeagueId?.[leagueId],
            ...newValue.byLeagueId[leagueId],
          },
        },
      },
    }),
  },
});

export const makeRemovePlayersFromRosterMutation = (
  facilityId: string,
  leagueId: string,
  body: LeagueRosterEntryDto
): AppQueryConfig => ({
  url: urls.deletePlayerFromRoster(facilityId, leagueId, body.userGuid),
  queryKey: `makeRemovePlayersFromRosterMutation:${facilityId}-${leagueId}-${body.userGuid}`,
  options: {
    ...leagueQueryDefaultOptions(),
    method: "DELETE",
  },
  transform: (responseJson: LeagueRosterEntryDto[]) => {
    let obj: { [id: string]: LeagueRosterEntryDto } = {};

    responseJson.map((player) => {
      return (obj[player?.userGuid] = player);
    });

    return {
      leagueRoster: {
        byLeagueId: {
          [leagueId]: obj,
        },
      },
    };
  },
  update: {
    leagueRoster: (reduxState) => {
      delete reduxState.byLeagueId[leagueId][body?.userGuid];
      return reduxState;
    },
  },
});

export const createLockerUser = async (body: LockerUser): Promise<any> => {
  return await axios
    .post<LockerUser>(urls.createLockerUser(), body, {
      ...leagueQueryLockerAPIDefaultOptions(),
    })
    .then((res) => {
      return {
        status: res.status,
        data: res.data,
      };
    })
    .catch((error) => {
      return { data: error.response.data.title, status: error.response.status };
    });
};
