import { LeagueRosterEntryDto } from "Models";
import { QueryState, EntitiesState } from "redux-query";
import { RunMutation, useMutation, useRequest } from "redux-query-react";
import {
  getRosterByLeagueId,
  makeAddPlayersToRosterMutation,
  makeRemovePlayersFromRosterMutation,
} from "./leagueRosterQueries";
import { UseDataInfo } from "QueryTypes";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import axios from "axios";
import { useState } from "react";
import { leagueQueryDefaultOptions } from "./leagueHelpers";

export const useAddPlayersToLeagueRoster = (
  facilityId: string,
  leagueId: string,
  playerId: string
): [QueryState, RunMutation<EntitiesState, LeagueRosterEntryDto[]>] => {
  const [addPlayersToRosterQueryState, addPlayersToRoster] = useMutation(() =>
    makeAddPlayersToRosterMutation(facilityId, leagueId, playerId)
  );

  return [addPlayersToRosterQueryState, addPlayersToRoster];
};

export const useRemovePlayerFromLeagueRoster = (
  facilityId: string,
  leagueId: string
): [QueryState, RunMutation<EntitiesState, LeagueRosterEntryDto[]>] => {
  const [
    removePlayerFromRosterQueryState,
    removePlayerFromRoster,
  ] = useMutation((body: any) =>
    makeRemovePlayersFromRosterMutation(facilityId, leagueId, body)
  );

  return [removePlayerFromRosterQueryState, removePlayerFromRoster];
};

export const useAddPlayersToLeagueRosterAxios = (
  facilityId: string,
  leagueId: string,
  playerId: string
): [boolean, () => Promise<void>] => {
  const [loading, setLoading] = useState(false);

  const addPlayersToRoster = async () => {
    setLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/player/${playerId}`
      );
    } catch (error) {
      console.error("Error adding players to roster", error);
    } finally {
      setLoading(false);
    }
  };

  return [loading, addPlayersToRoster];
};

export const removePlayerFromLeagueRosterAxios = async (
  facilityId: string,
  leagueId: string,
  playerId: string
): Promise<() => Promise<void>> => {
  const url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/player/${playerId}`;
  const { data } = await axios.delete(url, leagueQueryDefaultOptions());

  return data;
};

export const useLeagueRosterByLeagueId = (
  facilityId: string,
  leagueId: string
): UseDataInfo<LeagueRosterEntryDto[]> => {
  const [queryState, refreshLeagueRoster] = useRequest(
    getRosterByLeagueId(facilityId, leagueId)
  );

  const leagueRoster = useSelector(
    (state: RootState): LeagueRosterEntryDto[] => {
      if (!state.entities.leagueRoster?.byLeagueId?.[leagueId]) return [];

      let leagueRoster: LeagueRosterEntryDto[] = Object.values<LeagueRosterEntryDto>(
        state.entities?.leagueRoster?.byLeagueId?.[leagueId]
      );

      return leagueRoster.sort(
        (a: LeagueRosterEntryDto, b: LeagueRosterEntryDto) =>
          a.firstName && b.firstName
            ? a.firstName > b.firstName
              ? 1
              : -1
            : a.userName > b.userName
            ? 1
            : -1
      );
    }
  );

  return [leagueRoster, queryState, refreshLeagueRoster];
};
