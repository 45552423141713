import { useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "utils/hooks";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { ArrayHelper } from "utils/arrays/arrayHelpers";
import { RoundConfiguration, SimulatorReservationPayload } from "Models";
import {
  useCreateSimulatorReservation,
  useSelectedQueueItem,
  useUpdateSimulatorReservation,
} from "modules/simulator-reservations/simulatorReservationHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { showCustomNotification } from "utils/notifications/notificationHelpers";
import ReactTooltip from "react-tooltip";
import Row from "components/ui/layout/Row";
import { GenericNotificationType } from "utils/notifications/notificationHelpers";
import { QueryState } from "redux-query";
import {
  ClubhouseEvents,
  pendoTrackEventWithProperties,
} from "utils/pendo/pendoHelpers";
import { roundConfigEnumsAsReactSelectOptions } from "utils/enums/enumHelper";
import { queueItemTrayAction } from "ui-modules/nav/navSlice";
import DatePicker from "react-datepicker";
import RoundConfigurationSelect from "../round-control/round-configuration-form/RoundConfigurationSelect";
import SimulatorSelect from "../round-control/round-configuration-form/SimulatorSelect";
import StartGameForm from "../round-control/round-configuration-form/StartGameForm";
import { ErrorMessage } from "@hookform/error-message";
import { DateTime } from "luxon";
import styles from "../SingleFacilityControlDashboard.styles";
import { getSimulatorsByCurrentFacility } from "modules/simulator/simulatorSelectors";
import { useSelector } from "react-redux";
import { CgSpinner } from "react-icons/cg";
import { RoundType } from "types/enums";
import { modalAction } from "ui-modules/modals/modalSlice";
import { ModalType } from "utils/modals/modalHelpers";
import toast, { useToasterStore } from "react-hot-toast";

const QueueItemContainer = () => {
  const dispatch = useAppDispatch();
  const methods = useForm();
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedQueueItem = useSelectedQueueItem(currentFacilityId);
  const simulators = useSelector(getSimulatorsByCurrentFacility);
  const toastIsActive = useToasterStore().toasts.length > 0 ? true : false;

  const [selectedSavedRound, setSavedRound] = useState<RoundConfiguration>();
  const [
    createSimulatorReservationQueryState,
    createSimulatorReservation,
  ] = useCreateSimulatorReservation(currentFacilityId);

  const [
    updateSimulatorReservationQueryState,
    updateSimulatorReservation,
  ] = useUpdateSimulatorReservation(
    selectedQueueItem?.id || "",
    currentFacilityId
  );

  const onSubmit = async (data: SimulatorReservationPayload) => {
    let reservationData;

    if (data.roundType === RoundType.GAME) {
      reservationData = {
        ...data,
        gameInfo: {
          ...data.gameInfo,
          ...data.gameConfiguration,
          players: data.roundInfo?.players,
          facilityId: currentFacilityId,
          simulatorId: data.simulatorId,
        },
      };
      delete reservationData.gameConfiguration;
    } else {
      reservationData = {
        ...data,
      };
    }

    if (selectedQueueItem) {
      await updateSimulatorReservation(reservationData);
      //await handleQueryStatus(updateSimulatorReservationQueryState);
    } else {
      await createSimulatorReservation(reservationData);
      //await handleQueryStatus(createSimulatorReservationQueryState);
    }

    pendoTrackEventWithProperties(
      selectedQueueItem
        ? ClubhouseEvents.EDIT_RESERVATION
        : ClubhouseEvents.CREATE_RESERVATION,
      {
        simulatorId: data.simulatorId,
        facilityId: currentFacilityId,
        courseName: data.roundInfo?.courseName,
        roundType: roundConfigEnumsAsReactSelectOptions.gameType.find(
          (option) => option.value === data.roundType
        )?.label,
        scoringType: roundConfigEnumsAsReactSelectOptions.scoringType.find(
          (option) => option.value === data.roundInfo?.scoringType
        )?.label,
        timer: data.time,
        players: data.roundInfo?.players?.length,
      }
    );

    dispatch(
      modalAction({
        isOpen: false,
        modalName: ModalType.QueueModal,
      })
    );
  };

  const handleQueryStatus = useCallback(
    async (queryState: QueryState) => {
      if (queryState.isFinished) {
        if (queryState.status === 200) {
          await dispatch(queueItemTrayAction({ isOpen: false }));
          !toastIsActive && toast.success("Round has been saved successfully");
        } else {
          showCustomNotification(
            "An error occurred. Please try your request again.",
            GenericNotificationType.ERROR
          );
        }
      }
    },
    [dispatch, toastIsActive]
  );

  const handleStartTime = (value: Date) => {
    methods.setValue("startTime", value);
    methods.setValue(
      "endTime",
      new Date(DateTime.fromJSDate(value).plus({ hours: 1 }).toISO())
    );
    methods.clearErrors("startTime");
  };

  const handleEndTime = (value: Date) => {
    methods.setValue("endTime", value);
    methods.clearErrors("endTime");
  };

  const filterTime = (date: { getTime: () => number }) => {
    const isPastTime = new Date().getTime() > date.getTime();
    return !isPastTime;
  };

  useEffect(() => {
    if (selectedSavedRound) {
      methods.setValue("roundType", selectedSavedRound?.roundType);
      methods.setValue("roundInfo", selectedSavedRound?.roundInfo);
      methods.setValue("autoContinue", selectedSavedRound?.autoContinue);
      methods.setValue("rangeTime", selectedSavedRound?.rangeTime);
      methods.setValue(
        "holes",
        ArrayHelper.convertBooleanToNumberArray(
          selectedSavedRound?.roundInfo.holesOriginallySelected
        )
      );
      if (selectedQueueItem) {
        methods.setValue("simulatorId", selectedQueueItem?.simulatorId);
        methods.setValue("startTime", new Date(selectedQueueItem?.startTime));
        methods.setValue("endTime", new Date(selectedQueueItem?.endTime));
        methods.setValue(
          "roundInfo.players",
          selectedQueueItem?.roundConfiguration?.roundInfo?.players
        );
        methods.setValue("range", selectedQueueItem?.roundConfiguration?.range);
      }
    } else {
      methods.setValue("simulatorId", simulators[0]?.id);
      methods.setValue("startTime", new Date(DateTime.local().toISO()));
      methods.setValue(
        "endTime",
        new Date(DateTime.local().plus({ minutes: 60 }).toISO())
      );
    }
  }, [selectedSavedRound, selectedQueueItem, methods, simulators]);

  useEffect(() => {
    if (selectedQueueItem) {
      setSavedRound(selectedQueueItem.roundConfiguration);
    }
  }, [selectedQueueItem]);

  useEffect(() => {
    methods.setValue("facilityId", currentFacilityId);
  }, [currentFacilityId, methods]);

  useEffect(() => {
    handleQueryStatus(createSimulatorReservationQueryState);
  }, [createSimulatorReservationQueryState, handleQueryStatus]);

  useEffect(() => {
    handleQueryStatus(updateSimulatorReservationQueryState);
  }, [updateSimulatorReservationQueryState, handleQueryStatus]);

  return (
    <div className="flex w-full h-full flex-col">
      <Row cssClasses="border-b border-gray-300 pb-6">
        <RoundConfigurationSelect onChange={setSavedRound} />
      </Row>
      <Row cssClasses="mb-8">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <SimulatorSelect
              simulatorId={
                selectedQueueItem?.simulatorId || simulators[0]?.id || ""
              }
            />
            <div className={styles.dateContainer}>
              <div>
                <span className={styles.dateSpan}>Start</span>
                <Controller
                  name="startTime"
                  control={methods.control}
                  rules={{ required: "Start time is required" }}
                  data-testid="selectStartTime"
                  defaultValue={new Date(DateTime.local().toISO())}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      onChange={(date: Date) => handleStartTime(date)}
                      selected={methods.watch("startTime")}
                      showTimeSelect
                      timeIntervals={5}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      className={styles.datePicker}
                      maxDate={
                        new Date(DateTime.local().plus({ days: 1 }).toISO())
                      }
                      minDate={new Date(DateTime.local().toISO())}
                      filterTime={filterTime}
                      showPopperArrow={false}
                    />
                  )}
                />
                <ErrorMessage
                  errors={methods.formState.errors}
                  name="startTime"
                  render={({ message }) => (
                    <div className="text-sm text-red-500">{message}</div>
                  )}
                />
              </div>
              <div>
                <span className={styles.dateSpan}>End</span>
                <Controller
                  name="endTime"
                  control={methods.control}
                  rules={{ required: "End time is required" }}
                  data-testid="selectEndTime"
                  defaultValue={
                    new Date(DateTime.local().plus({ minutes: 60 }).toISO())
                  }
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      onChange={(date: Date) => handleEndTime(date)}
                      selected={methods.watch("endTime")}
                      showTimeSelect
                      timeIntervals={5}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      className={styles.datePicker}
                      maxDate={
                        new Date(DateTime.local().plus({ days: 1 }).toISO())
                      }
                      minDate={new Date(DateTime.local().toISO())}
                      filterTime={filterTime}
                      showPopperArrow={false}
                    />
                  )}
                />
                <ErrorMessage
                  errors={methods.formState.errors}
                  name="endTime"
                  render={({ message }) => (
                    <div className="text-sm text-red-500">{message}</div>
                  )}
                />
              </div>
            </div>
            <StartGameForm
              simulatorId={methods.watch("simulatorId")}
              queueItem={true}
              allowCreateLockerUser={true}
              selectedQueueItem={selectedQueueItem}
            />

            <button type="submit" className={styles.submit} tabIndex={4}>
              <span className="inline">
                {createSimulatorReservationQueryState?.isPending ? (
                  <>
                    <CgSpinner className="size-4 animate-spin mr-2" />
                    Saving Reservation...
                  </>
                ) : (
                  `${selectedQueueItem ? "Update" : "Create"} Reservation`
                )}
              </span>
            </button>
          </form>
        </FormProvider>
      </Row>
      <ReactTooltip backgroundColor="#777" textColor="#fff" />
    </div>
  );
};
export default QueueItemContainer;
