import { useState } from "react";
import { motion } from "framer-motion";
import { MdClose } from "react-icons/md";
import { CgSpinner } from "react-icons/cg";
import { FaAngleDown } from "react-icons/fa";
import { MotionVariantType } from "types/enums";
import { useToasterStore } from "react-hot-toast";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { globalStyles } from "components/globalStyles";
import { styles } from "../teams/RosterAndGroups.styles";
import { ClubhouseRoundTeamDto, TeamRoundPayload } from "Models";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useLeagueRosterByLeagueId } from "modules/leagues/leagueRosterHooks";
import { useLeagueLeaderboardByLeagueId } from "modules/leagues/leagueScorecardHooks";
import { useLeagueTeamsByFacilityAndLeagueId } from "modules/leagues/leagueTeamsHooks";
import {
  pendoTrackBasicEvent,
  ClubhouseEvents,
} from "utils/pendo/pendoHelpers";
import {
  dailyQueueContainer,
  dailyQueueListItemVariant,
} from "utils/animations/animationHelper";
import { useGetClubhouseLeagueRoundDto } from "modules/leagues/leagueRoundHooks";
import {
  isApiStatusSuccess,
  getApiResponseErrorMessage,
} from "utils/api/apiHelper";
import {
  useCreateTeamLeagueGroup,
  useLeagueGroupByFacilityLeagueRoundId,
} from "modules/leagues/leagueGroupHooks";
import {
  useGetSelectedLeague,
  useIsMatchLeague,
  useSelectedLeagueRoundId,
} from "modules/leagues/leagueHooks";
import {
  showCustomNotification,
  GenericNotificationType,
} from "utils/notifications/notificationHelpers";
import Tippy from "@tippyjs/react";
import SpinnerAnimation from "components/svg/SpinnerAnimation";
import {
  useCreateLeagueMatch,
  useGetLeagueRoundMatches,
} from "modules/leagues/leagueMatchHooks";
import { GetMatchResponseDtoParticipant } from "MatchPlayModels";

interface AvailableLeagueRoundGroupsProps {
  team: ClubhouseRoundTeamDto;
}

const TeamSyncGroupsRoster = () => {
  const toastIsActive = useToasterStore().toasts.length > 0 ? true : false;
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedLeague = useGetSelectedLeague();
  const selectedLeagueRoundId = useSelectedLeagueRoundId();
  const isMatchPlayLeague = useIsMatchLeague();
  const defaultGroupSize = selectedLeague?.settings?.defaultGroupSize || 4;
  const leagueTeamSize = selectedLeague?.settings?.leagueTeamSize || 4;

  const [, setshowGroupView] = useState<boolean>(false);
  const [group, setAddToGroup] = useState<string[]>([]);
  const [showCreateGroup, setShowCreateGroup] = useState<boolean>(false);

  const [leagueRoster, leagueRosterQueryState, ,] = useLeagueRosterByLeagueId(
    currentFacilityId,
    selectedLeague?.id
  );

  const [createTeamGroupQueryState, createTeamGroup] = useCreateTeamLeagueGroup(
    currentFacilityId,
    selectedLeague?.id,
    selectedLeagueRoundId
  );

  const [, , refreshLeagueRound] = useGetClubhouseLeagueRoundDto(
    currentFacilityId,
    selectedLeague?.id,
    selectedLeagueRoundId
  );

  const [
    leagueTeams,
    ,
    refreshLeagueTeams,
  ] = useLeagueTeamsByFacilityAndLeagueId(
    currentFacilityId,
    selectedLeague?.id
  );

  const [
    leagueRoundGroups,
    ,
    refreshLeagueRoundGroups,
  ] = useLeagueGroupByFacilityLeagueRoundId(
    currentFacilityId,
    selectedLeague?.id,
    selectedLeagueRoundId
  );

  const [leagueLeaderboard, ,] = useLeagueLeaderboardByLeagueId(
    currentFacilityId,
    selectedLeague?.id
  );

  const [leagueMatches] = useGetLeagueRoundMatches(
    currentFacilityId,
    selectedLeague?.id,
    selectedLeagueRoundId
  );

  const [, createSingleMatch] = useCreateLeagueMatch(
    currentFacilityId,
    selectedLeague?.id,
    selectedLeagueRoundId
  );

  const roundGroupNumbers = leagueRoundGroups
    ?.filter((player) => player.groupNumber && player.groupNumber !== 0)
    .map((player) => player.groupNumber)
    .filter((value, index, array) => array.indexOf(value) === index);

  function nextRoundGroupNumber() {
    let groupNumbers = leagueRoundGroups
      ?.map((player) => player?.groupNumber)
      .filter((groupNumber) => groupNumber !== undefined) as number[];

    return groupNumbers?.length > 0 ? Math.max(...groupNumbers) + 1 : 1;
  }

  const handleTeamPlayerCheckboxChange = (teamId: string) => {
    group.some((groupTeamId) => groupTeamId === teamId)
      ? setAddToGroup(group.filter((id) => id !== teamId))
      : group.length <= defaultGroupSize / leagueTeamSize &&
        setAddToGroup([...group, teamId]);
  };

  function getPositionFromLeaderboardByTeamId(teamId: string): string {
    let placeDisplay = "";

    leagueLeaderboard?.scores?.forEach((score) => {
      if (score.teamId === teamId) {
        placeDisplay = score.placeDisplay || "";
      }
    });

    return placeDisplay;
  }

  const handleShowGroupCreate = () => {
    setShowCreateGroup(true);
    setshowGroupView(false);
  };

  const handleCreateNewGroup = async () => {
    const teamGroupPayload: TeamRoundPayload[] = group?.map((teamId) => ({
      teamId,
      groupNumber: nextRoundGroupNumber(),
      teamPlayersUserGuids:
        leagueTeams
          ?.find((team) => team.id === teamId)
          ?.teamMembers?.map((player) => player.playerId)
          .flat()
          .filter((playerId): playerId is string => playerId !== undefined) ||
        [],
    }));

    const response = await createTeamGroup(teamGroupPayload);

    if (response && isApiStatusSuccess(response?.status)) {
      showCustomNotification(
        "Group created successfully",
        GenericNotificationType.SUCCESS,
        "🎉"
      );
      setAddToGroup([]);
      await refreshLeagueTeams();
      await refreshLeagueRoundGroups();
      await refreshLeagueRound();
      pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_ROUND_CREATE_GROUP);
    } else if (!toastIsActive && response) {
      showCustomNotification(
        getApiResponseErrorMessage(response?.body),
        GenericNotificationType.ERROR
      );
    }
  };

  const handleCreateNewMatch = async () => {
    let response;
    let groupNumber = nextRoundGroupNumber();
    let participantNumber = 0;

    const singleMatchTeamParticipants = group.map((teamId) => ({
      teamId: teamId,
      player:
        leagueTeams
          ?.find((team) => team.id === teamId)
          ?.teamMembers?.map((player) => player.playerId)
          .flat()
          .filter((playerId): playerId is string => playerId !== undefined) ||
        [],
      number: (participantNumber += 1),
    }));

    const singleMatchPayload = {
      participants: singleMatchTeamParticipants,
      groupNumber: groupNumber,
    };

    response = await createSingleMatch(singleMatchPayload);

    if (response && isApiStatusSuccess(response?.status)) {
      showCustomNotification(
        "Match created successfully",
        GenericNotificationType.SUCCESS,
        "🎉"
      );
      setAddToGroup([]);
      refreshLeagueRound();
      pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_CREATE_MATCH);
      handleCreateNewGroup();
    } else {
      showCustomNotification(
        getApiResponseErrorMessage(response?.body),
        GenericNotificationType.ERROR
      );
    }
  };

  const handleRemoveTeamFromGroup = async (
    groupNumber: number,
    team: ClubhouseRoundTeamDto
  ) => {
    let teamGroupPayload = [
      {
        teamId: team.id,
        teamPlayersUserGuids: team?.teamMembers?.map(
          (player) => player.playerId
        ),
      },
    ];

    let response = await createTeamGroup(teamGroupPayload);

    if (response && isApiStatusSuccess(response?.status)) {
      showCustomNotification(
        `${team?.name} removed from group ${groupNumber} successfully`,
        GenericNotificationType.SUCCESS,
        "🎉"
      );
      pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_ROUND_CREATE_GROUP);
      await refreshLeagueRound();
      await refreshLeagueRoundGroups();
    } else {
      showCustomNotification(
        getApiResponseErrorMessage(response?.body),
        GenericNotificationType.ERROR
      );
    }
  };

  const handleAddTeamToGroup = async (
    team: ClubhouseRoundTeamDto,
    groupNumber: number
  ) => {
    let response = await createTeamGroup([
      {
        teamId: team.id,
        teamPlayersUserGuids: team?.teamMembers?.map(
          (player) => player.playerId
        ),
        groupNumber: groupNumber,
      },
    ]);

    if (response && isApiStatusSuccess(response?.status)) {
      showCustomNotification(
        team.name + " added to Group " + groupNumber,
        GenericNotificationType.SUCCESS,
        "🎉"
      );
      setAddToGroup([]);
      await refreshLeagueRound();
      await refreshLeagueRoundGroups();
      pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_ROUND_CREATE_GROUP);
    } else {
      if (!toastIsActive && response) {
        showCustomNotification(
          getApiResponseErrorMessage(response?.body),
          GenericNotificationType.ERROR
        );
      }
    }
  };

  function getGroupNumberFromPlayerId(playerId: string): number | undefined {
    return leagueRoundGroups?.find(
      (groupMember) => groupMember.playerId === playerId
    )?.groupNumber;
  }

  function getGroupNumberFromTeamId(teamId: string): number | undefined {
    return leagueRoundGroups?.find(
      (groupMember) => groupMember.teamId === teamId
    )?.groupNumber;
  }

  function getMatchByPlayerId(
    playerId: string
  ): GetMatchResponseDtoParticipant[] | undefined {
    let matchie;
    leagueMatches?.forEach((match) => {
      match?.participants?.forEach((participant) => {
        participant.teamMembers?.forEach((member) => {
          if (member.playerId === playerId) {
            matchie = match.name;
          }
        });
      });
    });
    return matchie;
  }

  const ShowTeamGroupNumber: React.FC<AvailableLeagueRoundGroupsProps> = (
    props
  ) => {
    const { team } = props;
    const player = leagueRoundGroups?.find(
      (player) => player.teamId === team.id
    );

    async function switchPlayerGroup(newGroupNumber: number) {
      if (player?.groupNumber) {
        await handleRemoveTeamFromGroup(player?.groupNumber, team);
      }
      await handleAddTeamToGroup(team, newGroupNumber);
    }

    return roundGroupNumbers?.length > 0 ? (
      <div>
        <Tippy
          className="poppins text-black text-xs bg-gray-100 dark:bg-zinc-800 dark:text-gray-50 left-0 rounded-xl float-right inline"
          placement="bottom"
          allowHTML={true}
          appendTo={document.body}
          interactive={true}
          interactiveBorder={10}
          zIndex={9999}
          content={
            <div className="">
              {roundGroupNumbers.length > 0 && !showCreateGroup && (
                <ul className="z-9999 p-2 border rounded-xl">
                  {roundGroupNumbers?.map((groupNumber) =>
                    leagueRoundGroups?.filter(
                      (player) => player.groupNumber === groupNumber
                    )?.length === defaultGroupSize ? (
                      <li
                        key={groupNumber}
                        className="border-b border-gray-200 px-1 py-2 last:border-none"
                      >
                        <button className="cursor-not-allowed text-gray-400">
                          Group {groupNumber} is full
                        </button>
                      </li>
                    ) : (
                      groupNumber &&
                      groupNumber !== player?.groupNumber && (
                        <li
                          key={groupNumber}
                          className="border-b border-gray-200 px-1 py-2 last:border-none"
                        >
                          <button
                            className="cursor-pointer"
                            onClick={() => switchPlayerGroup(groupNumber)}
                          >
                            Group {groupNumber}
                          </button>
                        </li>
                      )
                    )
                  )}
                  <li className="border-b border-gray-200 px-1 py-2 last:border-none">
                    <button
                      className="cursor-pointer"
                      onClick={() => handleShowGroupCreate()}
                      disabled={leagueRoster.length === 0 ? true : false}
                    >
                      Create Group
                    </button>
                  </li>
                </ul>
              )}
            </div>
          }
        >
          <div className="rounded-xl border inline px-2 py-0.5 border-gray-200 hover:bg-gray-100 hover:border-gray-400 cursor-pointer">
            <span className="ml-0.5">
              {player?.groupNumber ? player?.groupNumber : "Add"}
            </span>
            {createTeamGroupQueryState?.isPending ? (
              <CgSpinner className="size-3 animate-spin ml-1" />
            ) : (
              <FaAngleDown className="size-3 fill-gray-500 text-gray-500 ml-1" />
            )}
          </div>
        </Tippy>
        {player?.groupNumber && (
          <button
            className={`ml-2 mb-1.5 inline ${showCreateGroup ? "hidden" : ""}`}
            onClick={() =>
              handleRemoveTeamFromGroup(player?.groupNumber || 0, team)
            }
          >
            {createTeamGroupQueryState?.isPending ? (
              <CgSpinner className="size-3 animate-spin mb-0.5" />
            ) : (
              player?.groupNumber && (
                <AiOutlineMinusCircle className="size-3 fill-gray-400 text-gray-400 hover:fill-red-500 hover:text-red-500 mb-0.5" />
              )
            )}
          </button>
        )}
      </div>
    ) : (
      <div className="text-xs text-gray-500 dark:text-gray-50 relative w-full text-left">
        <button
          className=""
          onClick={(e) => {
            e.preventDefault();
            setShowCreateGroup(true);
          }}
        >
          {showCreateGroup ? "" : "Create"}
        </button>
      </div>
    );
  };

  const ShowPlayerMatch: React.FC<{ playerId: string }> = ({ playerId }) => {
    const playerMatch = getMatchByPlayerId(playerId);

    return playerMatch ? (
      <span className="text-gray-400 dark:text-gray-50">
        <span>{playerMatch}</span>
      </span>
    ) : null;
  };

  return leagueRosterQueryState.isFinished ? (
    leagueRoster && leagueRoster.length > 0 ? (
      <>
        <div className="pb-4">
          {showCreateGroup ? (
            <>
              <button
                className={`${globalStyles.primaryButton} ${globalStyles.buttonSmall}`}
                onClick={() =>
                  isMatchPlayLeague
                    ? handleCreateNewMatch()
                    : handleCreateNewGroup()
                }
              >
                {createTeamGroupQueryState &&
                createTeamGroupQueryState.isPending ? (
                  <>
                    <CgSpinner className="size-4 animate-spin mr-2" />{" "}
                    {isMatchPlayLeague ? "Saving Match..." : "Saving Group..."}
                  </>
                ) : isMatchPlayLeague ? (
                  "Save Match"
                ) : (
                  "Save Group"
                )}
              </button>
              <button
                className={`${globalStyles.tertiaryButton} ${globalStyles.buttonSmall}`}
                onClick={() => {
                  setShowCreateGroup(false);
                  setAddToGroup([]);
                }}
              >
                Close
              </button>
            </>
          ) : isMatchPlayLeague &&
            selectedLeague?.settings?.allowAsynchronousMatchPlay === false ? (
            <button
              onClick={(e) => handleShowGroupCreate()}
              className={`${globalStyles.primaryButton} ${globalStyles.buttonSmall}`}
            >
              Assign Match
            </button>
          ) : (
            <button
              className={`${globalStyles.primaryButton} ${globalStyles.buttonMedium} poppins`}
              onClick={() => handleShowGroupCreate()}
              disabled={
                leagueRoster.length < leagueRoundGroups?.length ? true : false
              }
            >
              Create Group
            </button>
          )}
        </div>
        {showCreateGroup && (
          <div className="text-xs text-gray-400 dark:text-gray-50 pb-4">
            ** Players must be assigned to a team before they can be added to a
            group. Players assigned to a group are not available to be added to
            a new group.
          </div>
        )}
        <div className="grid grid-cols-12 gap-2 my-2 text-xs poppins">
          <div className="col-span-1 text-gray-400 text-left">
            {showCreateGroup && (
              <button
                onClick={() => setAddToGroup([])}
                className="border-b border-zinc-600 dark:border-gray-50 text-brandGreen dark:text-white"
              >
                Clear
              </button>
            )}
          </div>
          <div className="col-span-9 sm:col-span-4 text-gray-400 text-left">
            Name
          </div>
          <div className="hidden sm:inline-block sm:col-span-3 text-gray-400 text-left truncate">
            {isMatchPlayLeague ? "Match" : "Email"}
          </div>
          <div className="hidden sm:inline-block sm:col-span-2 text-gray-400 text-left truncate">
            Group
          </div>
          <div className="hidden sm:inline-block sm:col-span-1 text-gray-400 text-left truncate">
            Pos
          </div>
          <div className="sm:inline-block col-span-1 text-gray-400 text-left">
            HCP
          </div>
        </div>
        <motion.ul
          variants={dailyQueueContainer}
          initial={MotionVariantType.HIDDEN}
          animate={MotionVariantType.SHOW}
          key={`leagueRosterList_${leagueRoster.length}`}
        >
          {leagueTeams?.map((team, index) => {
            return (
              <>
                <motion.li
                  animate={{ opacity: 1, x: 0 }}
                  layout
                  variants={dailyQueueListItemVariant}
                  className={`grid grid-cols-12 gap-3 py-4 text-xs poppins justify-items-start items-center border-b border-gray-100 dark:text-gray-200`}
                  key={`leagueTeamRoster-${team.id}-${index}`}
                >
                  <div
                    className="col-span-1 text-gray-400 dark:text-gray-200 text-center"
                    key={index}
                  >
                    {showCreateGroup && !getGroupNumberFromTeamId(team?.id) ? (
                      <input
                        type="checkbox"
                        onChange={() =>
                          handleTeamPlayerCheckboxChange(team?.id)
                        }
                        id={team?.id}
                        checked={group.some((teamId) => teamId === team?.id)}
                        disabled={
                          group.length === defaultGroupSize / leagueTeamSize &&
                          !group.some((teamId) => teamId === team?.id)
                        }
                        className={styles.checkbox}
                      />
                    ) : (
                      index + 1 + "."
                    )}
                  </div>
                  <div className="hidden sm:block col-span-4 text-ellipsis font-semibold">
                    {team.name}
                  </div>
                  <div className="hidden sm:inline-block sm:col-span-3 text-gray-400 text-left truncate w-full">
                    <ShowPlayerMatch playerId={team.teamMembers[0]?.playerId} />
                  </div>
                  <div className="hidden sm:inline-block col-span-3">
                    <ShowTeamGroupNumber team={team} />
                  </div>
                  <div className="col-span-1 self-center"></div>
                </motion.li>

                {team?.teamMembers?.map((player, index) => {
                  return (
                    <motion.li
                      animate={{ opacity: 1, x: 0 }}
                      layout
                      variants={dailyQueueListItemVariant}
                      className={`grid grid-cols-12 gap-3 py-4 text-xs poppins justify-items-start items-center border-b border-gray-100 dark:text-gray-200`}
                    >
                      <div
                        className="col-span-1 text-gray-400 dark:text-gray-200 text-center"
                        key={index}
                      ></div>
                      <div className="col-span-9 sm:col-span-4">
                        {player?.firstName
                          ? player.firstName + " " + player?.lastName
                          : player?.userName}
                      </div>

                      <div className="hidden sm:inline-block sm:col-span-3 text-gray-400 text-left truncate w-full">
                        <div className="text-ellipsis truncate min-w-0">
                          {!isMatchPlayLeague && player.email}
                        </div>
                      </div>
                      <div className="hidden sm:inline-block col-span-2">
                        <div className="text-gray-300 dark:text-gray-100 pl-2.5">
                          {getGroupNumberFromPlayerId(player.playerId)}
                        </div>
                      </div>
                      <div className="hidden sm:inline-block sm:col-span-1 text-gray-400 text-left truncate">
                        {getPositionFromLeaderboardByTeamId(team.id)}
                      </div>
                      <div className="col-span-1 self-center">
                        {player.handicap}
                      </div>
                    </motion.li>
                  );
                })}
              </>
            );
          })}
        </motion.ul>
      </>
    ) : (
      <div className={styles.noPlayersRosterMessage}>
        <MdClose className={styles.closeIcon} />
        No players found in roster. Go back to the Leagues page, then click on
        the League Roster button to add players to this league.
      </div>
    )
  ) : (
    <div className={styles.noPlayersRosterMessage}>
      <div className="flex items-center h-3/4 w-full">
        <SpinnerAnimation color={"#f2f2e6"} />
      </div>
    </div>
  );
};
export default TeamSyncGroupsRoster;
